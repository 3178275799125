.footer{
    width: 100%;
    /* height: 50vh; */
    padding: 20px;
    
    display: flex;
    justify-content: flex-start;
    
}
.footer ul{
    list-style: none;
}
.list-footer{
    padding-top: 20px;
}
.list-footerr{
    padding-top: 20px;
}

/* @media (max-width : 650px) {
   
   .footer{
    height: 100%;
   }
  } */