.hero1{
    color: #000000;
    width: 100%;
    height: 100vh;
    background-color: #f8f9fa17;
   
    border-radius: 23px;
    
    
}

.text-home{
    width: 100%;
    margin: 12px;
    padding-top: 87px;
}


h1 {
    margin-top: 15px;
    width: 100%;
    
    padding: 0px 0px 20px 0px;

}



.text {

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 225%;
}

.textt {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 225%;
    text-align: left;
}

.textt-swap {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 225%;
    text-align: left;

}

.boxxx{
    width: 100%;
    margin: 12px;
    line-height: 225%;
    
}
.dat-text{
    width: 100%;
    margin: 12px;
    line-height: 225%;

}


a{
    
    text-decoration: solid blueviolet underline;
}
ul .list-type1{
    list-style-type:circle ;
}
.div-color{
    width: 100%;
    height: 100%;
    background-color:  #f0f0f0;
}
.list-select{
    display: flex;
}
select{
    width:100% ;
    margin-left: 10px;
   
    border: 1px solid #ccc;
    border-radius: 4px;
}
@media (max-width : 650px) {
   
    .list-select{
        display: flex;
        flex-direction: column;
    }
    select{
        width: 20%;
    }
  }