.log-mess{
    line-height: 1.8;
    width: 100%;
    height: 200px;
    background-color: #747d6c8c;
    overflow-x: hidden;
    padding-top: 5px;
}
.message-li{
    margin-bottom: 20px;
}
.logs-text{
    width: 100%;
    height: 100%;
}