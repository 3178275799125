button{
    
    /* font-size: 60%;
    background-color: black;
    color: white;
    border-radius: 15%;
    padding: 4px;
    margin: 5px; */
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    margin: 5px;
    padding: 8px;
}
.err-message{
    color:crimson;
    
    font-style: italic;
}
.p-text{
    margin-top: 15px;
}
