.navbar{
    background-color: #99B6F2;
}
h3{
    padding-left: 5px;
}
.navbar-brand img{
    /* width: 6%; */
    width: 50px;
   
   
  }

  .navbar .navbar-nav {
    flex-direction: row-reverse;
    
  
  }
  .navbar-brand {
    width: 60%;
   display: flex;
    justify-content: flex-start;
    align-items: center;
    
}
  .navbar .nav-item{
    padding: 4px;
   
    
  }
  
  .navbar .nav-item a{
    text-decoration:underline;
  }
 
  @media (max-width : 375px) {
   
    .navbar-brand img {
     
     display: none;
  }
  h3{
    padding-left: 1px;
  }
  }
  /* @media (min-width : 2000px) {
   
    .navbar-brand img {
     width: 4%;
    
  }
  
  } */
 